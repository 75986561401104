<template>
    <auth-wrapper class="text-center font-app">
        <div class="mb-3 h2">
            <img
                @click="$router.push({ name: 'Home' })"
                class="auth-logo"
                alt="logo"
                src="@/assets/logo.png"
            />
        </div>
        <div class="space-top-1 space-bottom-2">
            <h2>Please try again</h2>
            <p class="lead">
                This account activation link is not valid. Please try signing up
                again.
            </p>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 mb-2">
                <btn
                    app
                    block
                    color="info"
                    @click="$router.push({ name: 'Home' })"
                    >Home
                </btn>
            </div>
            <div class="col-12 col-md-6 mb-2">
                <btn @click="$router.push({ name: 'Signup' })" app block
                    >Sign Up
                </btn>
            </div>
        </div>
    </auth-wrapper>
</template>

<script>
import { unauthClient } from '@/api/index.js';
import loading from '@/components/loading';
import authWrapper from './auth-wrapper';

export default {
    data() {
        return {
            valid: true,
        };
    },
    methods: {
        activate() {
            var token = this.$route.params.token;
            var uid = this.$route.params.uid;
            var newEmail = this.$route.params.new_email;
            if (newEmail == 'new_account') {
                newEmail = null;
            }

            var payload = {
                token: token,
                uid: uid,
                new_email: newEmail,
            };

            return unauthClient
                .post('/activate-account/', payload)
                .then((resp) => {
                    console.log(resp);
                    if ('new_email' in resp.data) {
                        this.$store.commit('updateEmail', resp.data.new_email);
                    }
                    this.$store.commit('updateTokens', resp.data);
                    this.$router.push('/dashboard');
                })
                .catch((err) => {
                    this.valid = false;
                });
        },
    },
    components: {
        authWrapper,
    },
    mounted() {
        this.activate();
    },
};
</script>
